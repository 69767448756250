@font-face {
    font-family: 'Metropolis Regular';
    src: local('Metropolis Regular'), url('../assets/fonts/Metropolis-Regular.ttf') format('woff')
}

@font-face {
    font-family: 'Metropolis Medium';
    src: local('Metropolis Medium'), url('../assets/fonts/Metropolis-Medium.ttf') format('woff')
}

@font-face {
    font-family: 'Metropolis Bold';
    src: local('Metropolis Bold'), url('../assets/fonts/Metropolis-Bold.ttf') format('woff')
}

.font-regular {
    font-family: 'Metropolis Regular'
}

.font-medium {
    font-family: 'Metropolis Medium'
}

.font-bold {
    font-family: 'Metropolis Bold'
}