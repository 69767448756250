nav {
    padding: 3% 3% 0 3%;
    img {
        max-width: 13rem;
    }
    .menu {
        .nav-button {
            background-color: transparent;
            border: none;
            outline: none;
            text-transform: uppercase;
            font-size: .8rem;
            transition: all .2s;
            color: $black;
            text-decoration: none;
            margin: 0 1rem;
            &:hover {
                color: $blue;
            }
        }
    }
}