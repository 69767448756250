.home {
    .top-section {
        height: 30rem;
        // width: 40vw;
        padding: 0 3%;

        .bg {
            max-width: 80vw;
            position: absolute;
            object-fit: contain;
            top: -0.1rem;
            z-index: -1;
            right: 0;
            height: 50rem;
        }

        .blur {
            width: 50rem;
            max-width: 100vw;
            position: absolute;
            top: 0;
            z-index: -1;
            left: -10rem;
            height: 40rem;
        }

        .inner-container {
            height: 30rem;
            width: 40vw;
            padding-left: 10%;

            h1 {
                font-size: 2rem;
                color: $black
            }

            p {
                margin-top: 1rem;
                font-size: 1rem;
                color: $black
            }
        }
    }

    .debt-section {
        left: -5rem;
        padding: 3% 3% 0 0;

        img {
            object-fit: contain;
            width: 100%;
        }
        h2.top-title {
            display: none;
        }

        .text-container {
            padding-left: 5%;

            p {
                margin: 1.5rem 0 2rem 0;
                font-size: .9rem;

                &.text-top {
                    margin-bottom: 1rem;
                }
            }

            .main-button {
                margin-bottom: 1rem;
            }
        }
    }

    .contact-section {
        padding: 3% 0 0 3%;

        img {
            object-fit: contain;
            margin-top: -30rem
        }

        .inner-container {
            padding-left: 4rem;

            .numbers-container {
                border-radius: 10rem;
                padding: 1rem 1rem;
                box-shadow: 0 0 15px $lightBlue;
                -moz-box-shadow: 0 0 15px $lightBlue;
                -webkit-box-shadow: 0 0 15px $lightBlue;
                -o-box-shadow: 0 0 15px $lightBlue;
                margin: 2rem 0;

                a {
                    text-align: center;
                    color: $blue;
                    text-decoration: none;
                    word-break: keep-all;
                }
            }
            .inner-text-container {
                p {
                    font-size: .9rem;
                    margin: 1rem 0;
    
                    &.contact-text {
                        margin-top: 2rem
                    }
                }
                img {
                    display: none;
                }
            }
        }
    }

    .global-contact {
        margin-top: -15rem;
        padding: 0 3% 3% 3%;

        .text-container {
            padding-left: 5%;
            h2 {
                margin-bottom: 2rem;
            }

            h4 {
                color: $blue;
                font-size: 1.2rem
            }

            p.address {
                font-size: 1rem
            }

            .contact-inner {
                p {
                    margin-left: 1rem;
                }
            }
            a {
                margin-left: .5rem;
            }
        }
    }
}