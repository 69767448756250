.app {
    overflow: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;
}

.main-button {
    border-radius: 10rem;
    border: none;
    background-color: $gray;
    color: $white;
    padding: .8rem 1.5rem;
    font-size: .9rem;
    transition: all .2s;
    text-decoration: none;

    a {
        color: $white;
    }

    &:hover {
        opacity: .8;
    }
}

.icon-title {
    margin: 2rem 0;

    h2 {
        margin: 0 0 0 2rem;
    }
}

h2 {
    color: $black;
    margin-bottom: 1rem;
    font-size: 1.8rem
}

h3 {
    color: $black;
    margin: 2rem 0 1rem 0;
    font-size: 1.2rem
}

p {
    font-size: .9rem;
    margin: 1rem 0;
}