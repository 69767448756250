@media all and (max-width: 1650px) {
    .home {
        .global-contact {
            margin-top: -10rem;
        }
    }
}

@media all and (max-width: 1500px) {
    .home {
        .global-contact {
            margin-top: 0;
        }
    }

    .home {
        .contact-section {
            img {
                margin-top: -20rem
            }
        }
    }

    footer {
        margin-top: 3rem;

        .round {
            top: -5rem;
        }

        img {
            margin-top: 7rem;
        }
    }
}

@media all and (max-width: 1200px) {
    footer {
        margin-top: 3rem;

        .round {
            top: -4.5rem;
        }

        img {
            margin-top: 5rem;
        }
    }
}

@media all and (max-width: 1000px) {
    footer {
        margin-top: 3rem;

        .round {
            top: -4rem;
        }

        img {
            margin-top: 5rem;
        }
    }
}

@media all and (max-width: 768px) {
    nav {
        padding: 3% 6%;

        .menu {
            .nav-button {
                width: 100vw;
                margin: 1rem 0 0 0;
            }
        }
    }

    .home {
        .top-section {
            height: 30rem;
            width: 40vw;
            padding: 0 3%;
        }

        .contact-section {
            margin-bottom: 3%;

            img {
                display: none;
            }

            .inner-container {
                padding: 0 2rem;

                .numbers-container {
                    margin-bottom: 4rem;
                }

                .inner-text-container {
                    position: relative;

                    p {
                        font-size: .9rem;
                        margin: 1rem 20rem 0 0;

                        &.contact-text {
                            margin-top: 2rem
                        }
                    }

                    img {
                        display: block;
                        position: absolute;
                        top: 10rem;
                        height: 28rem;
                        right: -12rem
                    }
                }
            }
        }

        .debt-section {
            img {
                width: 55%;
            }

            h2.top-title {
                display: block;
                padding: 0 3%;
            }

            .text-container {
                h2 {
                    display: none;
                }
            }
        }

        .global-contact {
            margin-top: 0;

            img {
                margin-bottom: 2rem;
            }
        }
    }

    footer {
        margin-top: 3rem;

        .round {
            top: -3rem;
        }

        img {
            margin-top: 3rem;
        }
    }
}

@media all and (max-width: 650px) {
    .home {
        .contact-section {
            .inner-container {
                .inner-text-container {
                    p {
                        margin: 1rem 10rem 0 0;
                    }

                    img {
                        right: -10rem;
                        top: 10rem;

                    }
                }
            }
        }
    }
}

@media all and (max-width: 550px) {
    nav {
        padding-top: 2rem;
    }

    .home {
        .top-section {
            width: 100vw;
            height: 25rem;

            .inner-container {
                width: auto;
                height: auto;
                margin-top: 2rem;
                padding: 0 3%
            }

            .bg {
                max-width: 100vw;
                top: 8.2rem;
                height: 50rem;
            }
        }

        .contact-section {
            .inner-container {
                .inner-text-container {
                    p {
                        margin: 1rem 10rem 0 0;
                    }

                    img {
                        right: -8rem;
                        top: 9rem;
                        height: 25rem;
                        z-index: -1;
                    }
                }
            }
        }
    }

    .icon-title {

        h2 {
            margin: 0 0 0 1rem;
        }
    }

    footer {
        margin-top: 3rem;
        padding: 0 1rem 2rem 1rem;

        .round {
            top: -2rem;
        }

        img {
            margin-top: 3rem;
        }

        div {
            margin: .5rem 0;

            h5 {
                margin-bottom: .5rem;
            }
        }
    }
}

@media all and (max-width: 420px) {
    .home {
        .contact-section {
            .inner-container {
                padding: 0 1rem;

                .inner-text-container {
                    p {
                        margin: 1rem 6rem 0 0;
                    }

                    img {
                        right: -7rem;
                        top: 9rem;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 320px) {

    .contact-section {
        .inner-container {
            .numbers-container {
                a {
                    margin: .2rem;
                }
            }
        }
    }
}