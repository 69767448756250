footer {
    margin-top: 5rem;
    padding: 0 3% 3% 3%;
    background-color: $lightBlueBg;
    position: relative;
    .round {
        background-color: $white;
        position: absolute;
        width: 100vw;
        height: 10vw;
        top: -7rem;
        border-radius: 50%
    }   
    img {
        margin-bottom: 2rem;
        max-width:  10rem;
        margin-top: 10rem;
    }
    h5 {
        color: $black;
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    p, a {
        margin: .2rem 0;
        font-size: .7rem;
        color: $textGray;
    }
    a {
        text-decoration: none;
    }
    div {
        margin: 1rem 0;
    }
}